import { render, staticRenderFns } from "./IndustriesOptions.vue?vue&type=template&id=fe3dad24&scoped=true"
import script from "./IndustriesOptions.vue?vue&type=script&lang=ts"
export * from "./IndustriesOptions.vue?vue&type=script&lang=ts"
import style0 from "./IndustriesOptions.vue?vue&type=style&index=0&id=fe3dad24&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe3dad24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/Question.vue').default,IxTreeselectIndustry: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/treeselect/Industry.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VInput } from 'vuetify/lib/components/VInput';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VInput,VLayout})
