import "core-js/modules/es6.array.map.js";
import { useUpdateSelectedOptionsMutation } from '~/types/types';
export default defineComponent({
  inheritAttrs: false,
  props: {
    userInput: {
      type: Object,
      default: function _default() {}
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    var _useUpdateSelectedOpt = useUpdateSelectedOptionsMutation(),
      updateSelectedOptions = _useUpdateSelectedOpt.mutate;
    var valueChange = function valueChange(value) {
      var values = props.isMultiple ? value : [value];
      updateSelectedOptions({
        userInputId: props.userInput.id,
        values: values
      });
    };
    return {
      updateSelectedOptions: updateSelectedOptions,
      valueChange: valueChange
    };
  },
  computed: {
    value: function value() {
      var values = this.userInput.selectedOptions.map(function (e) {
        return e.id;
      });
      return this.isMultiple ? values : values[0];
    }
  }
});